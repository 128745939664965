<template>
  <l-layout
    class="l-quick-bi-design"
    :leftMove="false"
    :rightMove="false"
    :left="52"
    :right="320"
  >
    <template #left>
      <div class="l-quick-bi-design__left">
        <div class="myComponent-item" @click="handleViewer">
          <i class="myComponent-item-icon learun-icon-play-base"></i>
        </div>
        <div v-for="(myComponent, index) in componentList" :key="index">
          <el-divider></el-divider>
          <template v-for="item in myComponent.list">
            <el-tooltip
              :content="$t(item.title || item.label)"
              placement="right"
              :enterable="false"
              :key="item.type"
            >
              <div class="myComponent-item" @click="addComponent(item)">
                <i
                  v-if="item.icon"
                  class="myComponent-item-icon"
                  :class="item.icon"
                ></i>
                <img
                  class="myComponent-item-img"
                  v-if="item.img"
                  :src="`${rootUrl}${item.img}`"
                />
              </div>
            </el-tooltip>
          </template>
        </div>
      </div>
    </template>
    <template #right>
      <l-panel
        style="padding: 0; background: #fff"
        v-if="activeData"
        :title="`${activeData.label}`"
      >
        <component-config
          ref="componentConfig"
          :isDev="isDev"
          :data="activeData"
        ></component-config>
      </l-panel>
    </template>

    <l-panel v-if="!isApp" class="l-quick-bi-design__main" style="padding: 0">
      <div
        class="l-rblock l-quick-bi-design__main-content"
        ref="pcPanel"
        :style="{
          background: layout.length == 0 ? `url(${imgUrl}) no-repeat 50%` : '',
        }"
      >
        <grid-layout :col-num="120" :row-height="1" :layout.sync="layout">
          <grid-item
            v-for="item in layout"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :maxH="item.maxH"
            :minH="item.minH"
            :maxW="item.maxW"
            :minW="item.minW"
            :class="{ 'active-item': item.i === activeId }"
            @resized="resizedComponent"
            @click.native="clickComponent(item)"
          >
            <bi-item
              :isDev="isDev"
              :data="item"
              :isShow="isShow"
              :isRefresh="isRefresh"
            ></bi-item>
            <div class="mask"></div>
            <el-button
              :title="$t('复制')"
              v-if="item.i === activeId"
              @click.stop="addComponent(item)"
              class="action-clone"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              v-if="item.i === activeId"
              @click.stop="removeComponent(item.i)"
              class="action-delete"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </grid-item>
        </grid-layout>
      </div>
    </l-panel>
    <div v-else class="l-rblock"  style="padding: 8px">
      <div
        class="l-rblock l-quick-bi-design__app-content"
        ref="appPanel"
        :style="{
          background: layout.length == 0 ? `url(${imgUrl}) no-repeat 50%` : '',
        }"
      >
        <grid-layout :layout.sync="layout" :row-height="60">
          <grid-item
            v-for="item in layout"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :maxH="item.maxH"
            :minH="item.minH"
            :maxW="item.maxW"
            :minW="item.minW"
            :class="{ 'active-item': item.i === activeId }"
            @resized="resizedComponent"
            @click.native="clickComponent(item)"
          >
            <bi-item :data="item" :isShow="isShow"></bi-item>
            <div class="mask"></div>
            <el-button
              :title="$t('复制')"
              v-if="item.i === activeId"
              @click.stop="addComponent(item)"
              class="action-clone"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              v-if="item.i === activeId"
              @click.stop="removeComponent(item.i)"
              class="action-delete"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </grid-item>
        </grid-layout>
      </div>
    </div>

    <l-fullscreen-dialog
      :title="$t('设计预览')"
      :visible.sync="viewerVisible"
      :showOk="false"
      @opened="handleOpenedViewer"
      @closed="handleClosedViewer"
    >
      <l-quick-bi-viewer
        ref="quickViewer"
        :isPreview="true"
        :isApp="isApp"
      ></l-quick-bi-viewer>
    </l-fullscreen-dialog>
  </l-layout>
</template>
<script>
import BiItem from "./ibItem.vue";

import ComponentConfig from "../../config/componentConfig.vue";

export default {
  components: {
    BiItem,
    ComponentConfig,
  },
  name: "l-quick-bi-design",
  provide() {
    return {
      quickBiDesign: this,
    };
  },
  props: {
    componentList: {
      type: Array,
      default: () => [],
    },
    isShow: Boolean,
    isApp: {
      type: Boolean,
      default: false,
    },
    isDev: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUrl: require("../../img/widget-empty.png"),
      custmerformSchemes: {},
      viewerVisible: false,
      layout: [],
      activeData: null,
      isRefresh: false
    };
  },
  computed: {
    activeId() {
      if (this.activeData) {
        return this.activeData.i;
      } else {
        return "";
      }
    },
    biPanel() {
      return this.$refs.pcPanel;
    },
    appbiPanel() {
      return this.$refs.appPanel;
    },
    myComponentList(){
      const res = []
      this.componentList.forEach(t=>{
        res.push(...t.list)
      })
      return res
    }
  },
  mounted() {},
  methods: {
    handleClear() {
      this.layout = [];
      this.activeData = null;
    },
    handleViewer() {
      this.viewerVisible = true;
    },
    handleOpenedViewer() {
      this.$refs.quickViewer.set(this.$deepClone(this.layout));
    },
    handleClosedViewer() {
      this.$refs.quickViewer.reset();
    },

    addComponent(item) {
      const clone = this.$deepClone(item);

      // 删除不必要的属性
      delete clone.img

      const x = 0;
      let y = 0;
      const i = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      if (this.layout.length > 0) {
        const lastItem = this.layout[this.layout.length - 1];
        y = lastItem.y + lastItem.h;
      }

      const cell = { ...clone, i, x, y };
      this.layout.push(cell);

      if (this.activeData == null) {
        this.isRefresh = !this.isRefresh;
      }
      this.activeData = cell;

      this.$nextTick(() => {
        this.$refs.componentConfig && this.$refs.componentConfig.init(cell);
      });
    },
    removeComponent(i) {
      this.layout = this.layout.filter((item) => item.i !== i);
      this.activeData = null;
      this.isRefresh = !this.isRefresh;
    },
    clickComponent(item) {
      if (this.activeData == null) {
        this.isRefresh = !this.isRefresh;
      }
      this.activeData = item;
    },
    resizedComponent() {},

    get() {
      const data = this.$deepClone(this.layout);
      data.forEach(item => {
        delete item._activeCollapse
        delete item.moved
        delete item.maxH
        delete item.maxW
        delete item.minH
        delete item.minW
        delete item.label
        delete item._isInit

      });

      return data
    },
    set(data) {
      data.forEach(item =>{
        const componentItem = this.myComponentList.find(t=>t.type == item.type)
        if(componentItem){
          item._activeCollapse = componentItem._activeCollapse
          item.maxH = componentItem.maxH
          item.maxW = componentItem.maxW
          item.minH = componentItem.minH
          item.minW = componentItem.minW
          item.label = componentItem.label
        }
        item._isInit = true
      })
      this.layout = data;
      this.activeData = null;
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>