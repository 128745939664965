<template>
  <div
    v-if="ready"
    :class="[
      'l-rblock',
      'l-quick-bi-viewer',
      { 'l-quick-bi-app-viewer': isApp },
    ]"
  >
    <div class="l-rblock l-quick-bi-app-viewer-inner">
      <grid-layout
        :layout="layout"
        :col-num="isApp?12:120"
        :row-height="isApp?60:1"
        :is-draggable="false"
        :is-resizable="false"
      >
        <grid-item
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
        >
          <bi-item :data="item" :isPreview="isPreview"></bi-item>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import BiItem from "../../quickBIDesign/_src/ibItem.vue";
export default {
  components: {
    BiItem,
  },
  name: "l-quick-bi-viewer",
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    isApp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUrl: require("../../img/iPhone13.png"),
      layout: [],
      ready: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    set(data) {
      data.forEach((item) => {
        item._isInit = true;
      });
      this.layout = data;
      this.ready = true;
    },
    reset() {
      this.ready = false;
      this.layout.length = 0;
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>