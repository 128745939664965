<template>
    <div class="l-rblock" style="background-color: #fff" >{{$t(data.title || data.label)}}</div>
</template>
<script>
export default {
    name: 'bi-item-dev',
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isPreview:{
            type:Boolean,
            default:false
        },
        isShow:{
            type:Boolean,
            default:false
        }
    },
    computed:{
    }
}
</script>