import './styles/index.less'
import {GridLayout,GridItem} from 'vue-grid-layout'
import QuickBIDesign from './quickBIDesign'
import QuickBIViewer from './quickBIViewer'


import config from '../package.json'

const components = {
  QuickBIDesign,
  QuickBIViewer
}

const install = function (Vue) {
  if (install.installed) {
    return
  }

  Vue.component(GridLayout)
  Vue.component(GridItem)
  

  Object.values(components).map(component => {
    Vue.use(component)
  })




}

//  全局引用可自动安装
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
  window.learun_bi ={
    name:config.name,
    version:config.version,
    company:config.company
  }
}

export default {
  install,
  ...components
}

export {
  components
}
