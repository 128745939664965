<template>
  <div class="component-config q-bi-component-config">
    <el-form
      v-if="this.data && Object.keys(this.data).length > 0"
      label-width="72px"
      label-position="left"
      size="mini"
    >
      <!--<el-form-item label="标题">
        <el-input v-model="data.label"> </el-input>
      </el-form-item>
      <component v-if="!isDev" :is="componentName" :data="data"></component>-->

      <el-collapse :value="activeNames">
        <component
          ref="myComponent"
          v-if="!isDev"
          :is="componentName"
          :data="data"
        ></component>
        <el-collapse-item :title="$t('大小定位')" name="10">
          <el-form-item label="宽度">
            <el-input-number
              v-model="data.w"
              :max="data.maxW"
              :min="data.minW"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="高度">
            <el-input-number
              v-model="data.h"
              :max="data.maxH"
              :min="data.minH"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="x轴">
            <el-input-number
              v-model="data.x"
              :max="120"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="y轴">
            <el-input-number
              v-model="data.y"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <div
      v-else
      style="width: 100%; text-align: center; margin-top: 100%; color: #909399"
    >
      请选中组件进行设置！
    </div>
  </div>
</template>

<script>
export default {
  name: "component-config",
  props: ["data", "isDev"],
  data() {
    return {};
  },
  computed: {
    componentName() {
      return `${this.data.type}-config`;
    },
    activeNames() {
      return this.data._activeCollapse || ["1", "2"];
    },
  },
  methods: {
    init(cell) {
      this.$refs.myComponent &&
        this.$refs.myComponent.init &&
        this.$refs.myComponent.init(cell);
    },
  },
};
</script>
<style lang="less">
.q-bi-component-config {
  .el-collapse-item:last-child {
    margin-bottom: 0;
  }
}
</style>
