<template>
    <component :is="componentName" :data="data" :isPreview="isPreview" :isShow="isShow" :isRefresh="isRefresh" >
    </component>
</template>
<script>
export default {
    name: 'bi-item',
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isPreview:{
            type:Boolean,
            default:false
        },
        isShow:{
            type:Boolean,
            default:false
        },
        isRefresh:Boolean,
        isDev:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        componentName(){
            if(this.isDev){
                return 'bi-item-dev'
            }
            return `${this.data.type}-data-view`
        }
    }
}
</script>